<template>
    <app-layout>
        <template v-slot:header>
            <Header :title="$t('assessment_note_entry')"
                    :isColumns="false"
                    :isFilter="false"
            >
            </Header>
        </template>
        <template v-slot:header-mobile>
            <HeaderMobile :title="$t('assessment_note_entry')"
                          :isColumns="false"
                          :isFilter="false"
            >
            </HeaderMobile>
        </template>
        <div>
            <b-col v-if="!students.length" class="d-flex justify-content-center align-items-center" style="min-height: 400px">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
            </b-col>
            <div v-else>
                <b-table-simple class="border"  small caption-top responsive bordered>
                    <thead>
                    <tr>
                        <th rowspan="2" class="text-center justify-center">#</th>
                        <th rowspan="2" class="text-center justify-center">ID#</th>
                        <th rowspan="2" class="text-center justify-center">Student's Legal Name</th>
                        <th v-for="(assessment,key) in assessments" :key="'header-' + assessment.id" :colspan="assessments.length" class="border-bottom text-center" >
                            <div class="d-flex justify-content-center align-items-center mb-2">
                                <b-form-checkbox
                                    v-model="assessment.is_active"
                                    @change="handleSelectedHours(key)"
                                >
                                    <span >{{ assessment.name }}</span>
                                </b-form-checkbox>

                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(student, index) in students" :key="student.id" class="align-middle text-center">
                        <td class="text-center justify-center">{{ index + 1 }}</td>
                        <td class="text-center justify-center">{{ student.student_number }}</td>
                        <td class="text-center align-center">{{ student.name }} {{student.surname}}</td>
                            <td :colspan="assessments.length" v-for="(assessment,key) in assessments" :key="key">
                                <b-form-input  placeholder= "Note"
                                               :disabled="!assessment.is_active"
                                               v-if="selectedExams[assessment.id] && selectedExams[assessment.id][index]"
                                               v-model="selectedExams[assessment.id][index].score"  />
                            </td>
                    </tr>
                    </tbody>
                    <b-tfoot>
                        <tr class="border">
                            <td colspan="3"></td>
                                <td :colspan="assessments.length" v-for="(assessment,key) in assessments" :key="'save-button-' + assessment.id + '-' + key" class="text-center">
                                    <b-button
                                        v-if="assessment.is_active"
                                        variant="success"
                                        size="sm"
                                        class="w-100"
                                        @click="saveAttendance(assessment,key)"
                                    >
                                        Save
                                    </b-button>
                                </td>
                        </tr>
                    </b-tfoot>

                </b-table-simple>
            </div>

        </div>
    </app-layout>


</template>

<script>

import EslAttendanceService from "@/services/EslAttendanceService";
import AssessmentBulkEntryServices from "@/services/AssessmentBulkEntryServices";
export default {
    data() {
        return {
            students:[],
            assessments: [],
            selectedExams:{},
            enteredExamsGrade:[]
        };
    },
    metaInfo() {
        return {
            title: this.$t('assessment_note_entry'),
        }
    },
    created() {
        this.getStudents(true);
    },
    methods: {
        examOptions(){
          let newOptions = this.assessments.map(assessment => {
              return {
                  ...assessment,
                  is_active: false,
              }
          })
            this.assessments = newOptions
        },
        handleSelectedHours() {
            let count = 0;
            this.assessments.forEach(assessment => {
                if (assessment.is_active && count > 0) {
                    assessment.is_active = false;
                } else if (assessment.is_active) {
                    count += 1;
                }
            });
        },
        async getStudents() {
            const [studentList,assessmentTypes,enteredExamGrade] = await Promise.all([
                EslAttendanceService.getStudents(this.$route.params.sessionId),
                AssessmentBulkEntryServices.assessmentBulkEntry(this.$route.params.sessionId),
                AssessmentBulkEntryServices.getAssessmentScoreById(this.$route.params.sessionId)
            ]);
            this.assessments = assessmentTypes.data.data;
            this.students = studentList.data.data;
            this.enteredExamGrade = enteredExamGrade.data?.data?.assessment_types??[];
            this.examOptions()
            this.initializeExamScore()
        },
        initializeExamScore() {
            if (!this.assessments || !this.students) return;
            this.assessments.forEach(assessment => {
                this.$set(this.selectedExams, assessment.id, {});
                for (let i = 1; i <= this.assessments.length; i++) {
                    this.selectedExams[assessment.id]=this.students.map(student => ({
                        student_program_id: student.student_program_id,
                        score: null
                    }));
                }
            });
            if (this.enteredExamGrade) {
                Object.entries(this.enteredExamGrade).forEach(([evaluation_assessment_id, students]) => {
                    if (this.selectedExams[evaluation_assessment_id]){
                        students.scores.forEach(scores => {
                            const index  = this.selectedExams[evaluation_assessment_id].findIndex(student => student.student_program_id === scores.student_program_id);
                            if (index!==-1) {
                                this.selectedExams[evaluation_assessment_id][index] = scores;

                            }else{
                                this.selectedExams[evaluation_assessment_id].push(scores);
                            }
                        })
                    }
                })
            }
        },
        async saveAttendance(assessment) {
          if (!this.checkPermission('assessment_assessmentscoreupdateorcreate')){
            return  this.$toast.error(this.$t('unauthorized_operation'))
          }
            const formData = {
                evaluation_assessments_id: assessment.evaluation_assessments_id,
                session_id: this.$route.params.sessionId,
                scores: this.selectedExams[assessment.id]
            };
            try {
                await AssessmentBulkEntryServices.assessmentBulkEntrySave(formData);
                this.$toast.success('successful Grade entry');
                assessment.is_active=false;
                await this.getStudents();
            } catch (error) {
                this.showErrors(error);
            }
        }
    }
};
</script>

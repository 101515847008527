import Api from '@/services/Index';

const assessmentBulkEntry = async (sessionId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/sessions/${sessionId}/assessment-types`);
}
const assessmentBulkEntrySave = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(`/assessment-scores/update-or-create`,data);
}
const getAssessmentScoreById = async (sessionId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/assessment-scores/${sessionId}/assessment-types`);
}

export default {
    assessmentBulkEntry,
    assessmentBulkEntrySave,
    getAssessmentScoreById
}
